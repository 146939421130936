<template>
    <div class="container" v-if="!validateing" v-loading="loading">
        <div class="content" v-if="!loading" ref="scrollDiv">
            <div class="userinfo">
                <div class="logo"><el-avatar
                        :src="data.userInfo.icon || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
                </div>
                <div class="user-name">{{ data.showName }}</div>
            </div>

            <div class="title">
                <div class="label">分享日期：</div>
                <div class="main">{{ data.rpCurrentDate }}</div>
            </div>

            <div class="title">
                <div class="label">汇报对象：</div>
                <div class="main">{{ reportAcc }}</div>
            </div>
            <div class="title">
                <div class="label">推送群聊：</div>
                <div class="main">{{ reportGroup }}</div>
            </div>

            <div class="title" style="margin-bottom: 5px;">工作分享</div>
            <!-- <div class="title">【鱼那你一起 {{ showName }} {{ data.publishDate }} 分享】</div> -->
            <div class="body">
                <span style="font-size: 16px!important;" v-html="data.content"></span>
                <div style="color:#888;">
                    <span>发布时间：{{ data.createTime }}</span>
                    <span style="float: right;" @click="selectBody">
                        <i class="el-icon-chat-dot-round"></i><span>评论</span></span>
                </div>
            </div>

            <div class="comment-title">评论内容</div>
            <div class="comment-list">
                <!-- 评论 -->
                <div class="comment-item" v-for="(item, index) in commentList" :key="index">
                    <div class="comment-logo">
                        <el-avatar size="medium"
                            :src="item.icon || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
                    </div>
                    <div class="comment-content">
                        <div>
                            <span class="name">{{ item.showName }}</span>
                        </div>
                        <span v-if="item.replayShowName">回复 <span class="name">{{ item.replayShowName }}</span>：</span>
                        <span style="display: inline-block;" v-html="item.content"></span>
                        <div class="comment-footer">
                            <span class="time">{{ item.createTime }}</span>
                            <span class="replay" @click="selectComment(item)"><i class="el-icon-chat-dot-round"></i>
                                回复</span>
                        </div>
                    </div>
                </div>

                <div class="comment-empty" v-if="!commentList.length">
                    暂无评论内容
                </div>
            </div>
        </div>
        <div class="comment" v-loading="sending">
            <div class="comment-box" @click="scrollBottom">
                <HtEditor @handelCallUser="handelCallUser" ref="HtEditor" :placeholder="placeholder" :isFocus="isFocus" />
            </div>
            <div class="confirm" @click="sendComment">发送</div>
        </div>
    </div>
</template>

<script>
import moment from "moment"
import HtEditor from "@/components/HtEditor.vue"
import utils from "@/utils"
import { mapState } from "vuex"
export default {
    components: { HtEditor },
    data() {
        return {
            validateing: true,
            data: {},
            placeholder: "请输入评论内容",
            // reportors: "",
            reportAcc: "",
            reportGroup: "",
            comment: "",
            commentList: [],
            isFocus: false,
            replyId: "",
            callUser: [],
            loading: true,
            sending: false
        };
    },
    computed: {
        ...mapState({
            isLogin: state => state.isLogin,
            corp_id: state => state.corp_id,
            userInfo: state => state.userInfo,
            isAndroid: state => state.isAndroid,
            isWx: state => state.isWx,
        })
    },
    created() {
        if (!this.isLogin) {
            this.$https.post(this.$api.getOauthUrl, {
                app: "RP",
                redirect: window.location.origin,
                state: `id=${utils.getQueryString("id")}`
            }).then(res => {
                window.location.href = res
            })
            // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.corp_id}&redirect_uri=${encodeURI(window.location.origin)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect=dailyDetail?id=${utils.getQueryString("id")}`
        } else {
            this.validateing = false
        }
    },
    methods: {
        scrollBottom() {
            this.$nextTick(() => {
                let scrollElem = this.$refs.scrollDiv;
                scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
            });
        },
        selectBody() {
            this.scrollBottom()
            this.isFocus = true
            this.replyId = ""
            this.placeholder = "请输入评论内容"
            setTimeout(() => {
                this.isFocus = false
            }, 50)
        },
        selectComment(data) {
            this.scrollBottom()
            this.isFocus = true
            this.replyId = data.id
            this.placeholder = "回复" + data.showName
            setTimeout(() => {
                this.isFocus = false
            }, 50)
        },
        handelCallUser(user) {
            this.callUser.push(user)
        },
        sendComment() {
            if (this.sending) return
            const regx = /<[^>]*>|<\/[^>]*>/gm
            const contentValue = this.$refs.HtEditor.$refs.editor.innerHTML.replace(regx, "").replace(/&nbsp;/g, "").replace(new RegExp(/( )/g), "")
            if (!contentValue) {
                this.$message.warning("内容不能为空")
                return
            }
            this.sending = true
            const content = this.$refs.HtEditor.$refs.editor.innerHTML
            const atAccList = this.callUser.filter(el => content.includes('@' + el.name))
            this.$https.post(this.$api.replyRp, {
                "content": content,
                "rpId": this.data.id,
                "replyId": this.replyId,
                "atAccList": atAccList.map(el => el.id).join(",")
            }).then(res => {
                this.$message.success(this.replyId ? "回复成功" : "评论成功")
                this.sending = false
                this.replyId = ""
                this.placeholder = "请输入评论内容"
                this.$refs.HtEditor.clearValue()
                this.initComment()
            })
        },
        init() {
            this.$https.get(this.$api.dailyDetail + utils.getQueryString("id")).then(res => {
                const data = res
                this.data = Object.assign(data, {
                    userInfo: this.userInfo.platformExtraData.accsMap[data.accId] || {},
                    rpCurrentDate: moment(data.rpCurrentDate).format('YYYY-MM-DD'),
                    showName: this.userInfo.platformExtraData.accsMap[data.accId].showName
                })
                const rpAccs = res.rpAccs && res.rpAccs.replace(new RegExp("#", "g"), "")
                const rpGroups = res.rpGroups && res.rpGroups.replace(new RegExp("#", "g"), "")
                let reportAcc = []
                let reportGroup = []
                if (rpAccs) {
                    rpAccs.split(",").forEach(el => {
                        reportAcc.push(this.userInfo.platformExtraData.accsMap[el].showName)
                    })
                }
                if (rpGroups) {
                    rpGroups.split(",").forEach(el => {
                        reportGroup.push(this.userInfo.platformExtraData.qwAppRpCgRobot.cgRobots[el].title)
                    })
                }
                this.reportAcc = reportAcc.join("，")
                this.reportGroup = reportGroup.join("，")
                this.loading = false
            })
        },
        initComment() {
            this.isSend = true
            this.$https.get(this.$api.replyList + utils.getQueryString("id")).then(res => {
                this.isSend = false
                this.commentList = res.map(el => {
                    const userInfo = this.userInfo.platformExtraData.accsMap[el.accId] || {}
                    return Object.assign(el, {
                        showName: userInfo.showName,
                        icon: userInfo.icon,
                        replayShowName: el.replyId && this.userInfo.platformExtraData.accsMap[res.find(item => item.id == el.replyId).accId].showName,
                    })
                })
            })
        },
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        if (this.isAndroid && this.isWx) {
            const _this = this
            wx.ready(function () {
                _this.init()
                _this.initComment()
                wx.onHistoryBack((function () {
                    // window.location.href = window.location.origin + "/#/dashboard"
                    _this.$router.push({ name: "dashboard" })
                    return false
                }))
            })
        } else {
            this.init()
            this.initComment()
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    font-size: 14px;
    background: #fff;
    position: relative;
    height: 100%;

    .content {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 60px;
        overflow: auto;

        .userinfo {
            position: relative;
            overflow: hidden;

            .logo {
                float: left;
            }

            .user-name {
                float: left;
                margin-left: 10px;
                font-size: 16px;
                color: #111;
                font-weight: bold;
                line-height: 50px;
            }
        }

        .title {
            line-height: 22px;
            overflow: hidden;
            margin-bottom: 10px;

            .label {
                font-size: 13px;
                width: 70px;
                float: left;
            }

            .main {
                font-weight: bold;
                float: left;
                width: calc(100% - 70px);
                color: #333;
                font-size: 14px;
            }
        }

        .body {
            margin: 0 auto;
            min-height: 150px;
            background: #fffaf4;
            border: 1px solid #ddd;
            border-radius: 5px;
            text-align: left;
            padding: 10px;
            overflow: auto;
            line-height: 30px;
            margin-bottom: 12px;
            cursor: pointer;
        }



        .comment-title {
            line-height: 30px;
            color: #333;
        }

        .comment-list {
            position: relative;
            background-color: #f5f5f5;
            border-radius: 4px;
            padding: 10px;
            margin-bottom: 10px;

            .comment-item {
                cursor: pointer;
                line-height: 24px;
                margin-bottom: 5px;
                overflow: hidden;

                .comment-logo {
                    float: left;
                    margin-right: 8px;
                }

                .comment-content {
                    float: left;
                    width: calc(100% - 50px);
                    word-break: break-all;

                    /deep/p {
                        margin: 0 !important;
                    }

                    .name {
                        color: #409EFF;
                    }


                }
            }


            .comment-footer {
                position: relative;

                .time {
                    color: #b2b2b2;
                    font-size: 12px;
                }

                .replay {
                    color: #b2b2b2;
                    float: right;
                }

            }

            .highlight {
                color: #409EFF;
                margin-right: 5px;
            }

            .comment-empty {
                line-height: 60px;
                text-align: center;
                color: #999;
                font-size: 12px;
            }
        }
    }

    .comment {
        border-top: 1px solid #ddd;
        position: absolute;
        bottom: 0;
        height: 60px;
        width: 100%;

        .comment-box {
            margin-top: 8px;
            margin-left: 10px;
            display: inline-block;
            width: calc(100% - 80px);
        }

        .confirm {
            width: 50px;
            line-height: 32px;
            border-radius: 50px;
            background: #D9D9D9;
            text-align: center;
            margin: 6px 10px;
            position: absolute;
            right: 0;
            bottom: 8px;
            cursor: pointer;
        }
    }
}
</style>