const api = {
    qywxLogin: "/api/qwapp/qwauth",
    getOauthUrl: "/api/qwapp/getOauthUrl",
    login: "/api/login",
    logout: "/api/logout/qwapph5",
    systemInfo: "/api/systemInfo/qwapph5",

    addDaily: "/api/qwapp/rp/createRp",
    myDailyList: "/api/qwapp/rp/myRpPageList",
    dailyDetail: "/api/qwapp/rp/rpDetails/",
    replyRp: "/api/qwapp/rp/replyRp",
    loginAccRpSettings: "/api/qwapp/rp/loginAccRpSettings",

    replyList: "/api/qwapp/rp/listReply/",
    shareList: "/api/qwapp/rp/rp2mePageList",
    myRpLeavePageList: "/api/qwapp/rp/myRpLeavePageList",

    checkRp: "/api/qwapp/rp/checkCanCreateRp",
    

    addHoilday: "/api/qwapp/rp/createLeave",
    getHoildayDetail: "/api/qwapp/rp/rpleaveDetails/",

    qywxConfig: "/api/qwapp/createJsapiSignature"
}

export default api