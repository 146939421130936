<template>
    <div class="container" v-loading="loading">
        <div class="content" v-if="permissions && !loading">
            <!-- 运维工作台 -->
            <DevOps v-if="current === 0" />
            <Staff v-if="current === 1" />
            <Sharing v-if="current === 2" />
        </div>

        <div class="permission" v-if="!permissions"> <i class="el-icon-warning"></i> 暂无日报权限，请联系管理人员</div>
    </div>
</template>

<script>
import Staff from "./components/Staff.vue"
import { mapState } from "vuex"
export default {
    components: { Staff },
    data() {
        return {
            loading: false,
            current: 1,
            permissions: true,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            isAndroid: state => state.isAndroid,
            isWx: state => state.isWx,
        })
    },
    created() {
        if (this.userInfo.modules && !this.userInfo.modules.length && this.userInfo.loginInfo.superAdmin) {
            this.permissions = false
        }
    },
    methods: {
        changeTab(index) {
            this.current = index;
        }
    },
    mounted() {
        if (this.isAndroid && this.isWx) {
            this.loading = true
            const _this = this
            wx.ready(function () {
                _this.loading = false
                wx.onHistoryBack(() => {
                    wx.closeWindow();
                    return false;
                });
            })
        } else {
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin: 0 !important;

    .content {
        position: relative;
        padding: 10px 0;
        font-size: 14px;
    }

    .permission {
        margin-top: 300px;
        text-align: center;
        color: #666;
    }
}
</style>
 