<template>
    <div class="container" v-if="!validateing" v-loading="loading">
        <div class="content" v-if="!loading">
            <div class="form">
                <div class="form-item">
                    <div class="form-label">报备人</div>
                    <div class="form-content">{{ showName }}</div>
                </div>
                <div class="form-item">
                    <div class="form-label">本次报备对象</div>
                    <div class="form-content">{{ reportors }}</div>
                </div>
                <div class="form-item">
                    <div class="form-label">休假开始时间 <span class="red">*</span></div>
                    <div class="form-content">{{ startDate }}</div>
                </div>
                <div class="form-item">
                    <div class="form-label">休假结束时间 <span class="red">*</span></div>
                    <div class="form-content">{{ endDate }}</div>
                </div>
                <div class="form-item" style="padding-bottom: 12px;">
                    <div class="form-label">报备原因 <span class="red">*</span></div>
                    <div class="content">{{ reason }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment"
import { mapState } from "vuex"
import utils from "@/utils"

export default {
    data() {
        return {
            validateing: true,
            options: [],
            startDate: "",
            endDate: "",
            reason: '',
            creator: [],
            reportors: "",
            loading: true,
            showName: ""
        };
    },
    computed: {
        ...mapState({
            isLogin: state => state.isLogin,
            corp_id: state => state.corp_id,
            userInfo: state => state.userInfo,
            isAndroid: state => state.isAndroid,
            isWx: state => state.isWx,
        })
    },
    created() {
        if (!this.isLogin) {
            this.$https.post(this.$api.getOauthUrl, {
                app: "RP",
                redirect: window.location.origin,
                state: `id=${utils.getQueryString("id")}`
            }).then(res => {
                window.location.href = res
            })
            // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.corp_id}&redirect_uri=${encodeURI(window.location.origin)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect=hoildayDetail?id=${utils.getQueryString("id")}`
        } else {
            this.validateing = false
        }
    },
    methods: {
        init() {
            this.$https.get(this.$api.getHoildayDetail + utils.getQueryString("id")).then(res => {
                this.startDate = moment(res.startDate).format("YYYY-MM-DD")
                this.endDate = moment(res.endDate).format("YYYY-MM-DD")
                this.reason = res.content
                this.showName = this.userInfo.platformExtraData.accsMap[res.accId].showName
                let reportors = []
                const rpAccs = res.rpAccs.replace(new RegExp("#", "g"), "")
                if (rpAccs) {
                    rpAccs.split(",").forEach(el => {
                        reportors.push(this.userInfo.platformExtraData.accsMap[el].showName)
                    })
                }
                this.reportors = reportors.join("，")
                this.loading = false
            })
        }
    },
    mounted() {
        if (this.isAndroid && this.isWx) {
            const _this = this
            wx.ready(function () {
                _this.init()
                wx.onHistoryBack((function () {
                    window.location.href = window.location.origin + "/#/dashboard"
                    return false
                }))
            })
        } else {
            this.init()
        }
    }
}
</script>

<style lang="scss">
.container {
    background: #fff;
    height: 100%;
    width: 100%;
    min-height: 300px;

    .form {
        position: relative;
        padding-top: 10px;
    }

    .form-item {
        padding: 0 20px;
        margin-bottom: 5px;

        .form-label {
            color: #666;
            font-size: 12px;
            line-height: 30px;

            .red {
                color: red;
                margin-left: 5px;
            }
        }

        .form-content {
            color: #333;
            border: 1px solid #dfdede;
            border-radius: 4px;
            padding: 10px;
            font-size: 14px;
            min-height: 16px;
            line-height: 1.5;
            position: relative;

            .el-icon-arrow-down {
                position: absolute;
                right: 10px;
                top: 12px;
            }
        }

        .content {
            color: #333;
            word-break: break-all;
        }

    }
}
</style>
