<template>
  <div class="HtTable">
    <el-table :data="tableData" style="width: 100%" border @row-click="handelRowClick">
      <el-table-column v-for="(item, index) in tableHeader" :key="index" :prop="item.prop" :label="item.label"
        :min-width="item.width || ''" align="center">
        <template slot-scope="scope">
          <div style="cursor: pointer;">
            <div class="rich-text" v-if="item.type == 'text'">
              <div class="text" v-html="scope.row[item.prop]"></div>
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableHeader: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {}
  },
  methods: {
    handelRowClick(row) {
      this.$emit("handelRowClick", row)
    },
  }
}
</script>

<style lang="scss" scoped>
.HtTable {
  position: relative;

  /deep/.el-table thead .el-table__cell {
    padding: 5px 0;
    background-color: #f5f5f5;
    font-weight: normal;
    font-size: 13px;
  }

  /deep/.el-table__empty-text {
    font-weight: normal;
    font-size: 12px;
  }

  /deep/.el-table .el-table__cell {
    padding: 6px 0;
  }

  /deep/.el-table .cell {
    padding: 0 6px;
  }

  .rich-text {
    overflow: hidden;
    height: 22px;
  }

}
</style>