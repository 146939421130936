<template>
  <div class="login" v-if="!validateing">
    <div class="login-box">
      <div class="title">日报系统</div>
      <div class="logo">
        <!-- <img src="@/assets/logo.png" alt="" /> -->
      </div>
      <el-form>
        <el-form-item label=" ">
          <el-input v-model="userName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-input v-model="password" placeholder="请输入密码" type="password" @keyup.enter.native="login">
          </el-input>
        </el-form-item>
      </el-form>
      <!-- <div class="reset-password" @click="dialogVisible = true">忘记密码</div> -->
      <div class="btns">
        <el-button type="primary" @click="login">登录</el-button>
      </div>
    </div>
    <div class="login-box-top"></div>
    <div class="login-box-bottom"></div>
  </div>
</template>

<script>
import utils from "@/utils"
import { mapState } from "vuex"

export default {
  data() {
    return {
      userName: "",
      password: "",
      form: {
        email: "",
        passcode: "",
        password: "",
        confirmPassword: "",
        unitName: "",
      },
      detailId: "",
      timer: null,
      countDown: 0,
      disabledSend: false,
      dialogVisible: false,
      redirectPath: "",
      validateing: true
    };
  },
  computed: {
    ...mapState({
      isWx: state => state.isWx,
    })
  },
  created() {
    if (this.isWx) {
      if (utils.getQueryString('code')) {
        this.validateing = true
        this.$https
          .post(this.$api.qywxLogin, {
            code: utils.getQueryString('code'),
            state: utils.getQueryString('state'),
            platform: "qwapph5",
            app: "RP"
          })
          .then((res) => {
            if (window.location.href.includes("dailyDetail") || window.location.href.includes("hoildayDetail")) {
              this.detailId = window.location.href.split("_")[window.location.href.split("_").length - 1]
              this.redirectPath = window.location.href.includes("dailyDetail") ? "dailyDetail" : "hoildayDetail"
            }
            this.getSystemInfo()
          });
      } else {
        const href = window.location.href.includes("?") ? window.location.href.split("?")[1].split("#")[0] : window.location.rigin
        this.$https.post(this.$api.getOauthUrl, {
          app: "RP",
          redirect: window.location.origin,
          state: href || "state"
        }).then(res => {
          window.location.href = res
        })
      }
    } else {
      this.validateing = false
    }

  },
  methods: {
    confirm() {
      if (!this.form.passcode) {
        this.$message.warning("请输入邮箱");
        return;
      }
      if (!this.form.passcode) {
        this.$message.warning("请输入验证码");
        return;
      }
      if (!this.form.password) {
        this.$message.warning("请输入新密码");
        return;
      }
      if (this.form.password != this.form.confirmPassword) {
        this.$message.warning("两次输入新密码不一致");
        return;
      }
      this.$https
        .post(this.$api.forgetPassword, {
          email: this.form.email,
          passcode: this.form.passcode,
          password: this.form.password,
        })
        .then((res) => {
          this.$message.success("修改密码成功");
          this.dialogVisible = false;
        });
    },
    login() {
      this.$https
        .post(this.$api.login, {
          userName: this.userName,
          password: this.password,
          platform: "qwapph5"
        })
        .then((res) => {
          this.getSystemInfo()
        });
    },
    getSystemInfo() {
      this.$https
        .get(this.$api.systemInfo)
        .then((res) => {
          const data = res;
          this.$store.dispatch("setUserInfo", data);
          sessionStorage.setItem("admninUserInfo", JSON.stringify(data));
          if (this.redirectPath) {
            window.location.href = window.location.origin + "/#/" + this.redirectPath + "?id=" + this.detailId
          } else {
            window.location.href = window.location.origin + "/#/dashboard"
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  width: 100%;
  height: 100%;

  .login-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 50%;
    max-width: 300px;
    min-width: 200px;
    border-radius: 8px;
    padding: 30px;
    border: 1px solid #dedede;
    z-index: 1;
    background: #fff;
    box-shadow: 0 2px 12px 0 #989898;

    .logo {
      text-align: center;

      img {
        width: 100px;
      }
    }

    .title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .reset-password {
      float: right;
      font-size: 12px;
      position: relative;
      top: -10px;
      cursor: pointer;
    }

    .btns {
      text-align: center;

      .el-button {
        width: 100%;
      }
    }
  }

  .login-box-top {
    position: absolute;
    width: 100%;
    height: 50%;
    background: #eee;
  }

  .login-box-bottom {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50%;
    background: #eee;
  }
}

.btns {
  text-align: center;
}
</style>