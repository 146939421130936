<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: 'App',
  computed: {
    ...mapState({
      isLogin: state => state.isLogin,
      isAndroid: state => state.isAndroid,
      isiOS: state => state.isiOS,
    })
  },
  methods: {
    back() {
      this.$message.success(this.$route.name)
      if (this.$route.name == "login") {
        this.isAndroid ? wx.closeWindow() : window.close();
      }
    }
  },
  mounted() {
    // 安卓
    if (this.isAndroid) {
      this.$https.post(this.$api.qywxConfig, { url: window.location.origin + '/', app: "RP" }).then(data => {
        wx.config({
          beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ["onHistoryBack"] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
      })
    }
    // IOS
    if (this.isiOS) {
      const _this = this
      window.addEventListener('pageshow', function () {
        if (_this.isLogin && (window.location.pathname == "/login" || window.location.pathname == "/")) {
          wx.closeWindow()
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
#app {
  position: relative;
  width: 100%;
  height: 100%;
  background: #eee;
  // min-width: 960px;
}
</style>
