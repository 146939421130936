<template>
	<div class="containers">
		<div class="box">
			<div class="title"> 鱼你在一起-分享汇报 </div>
			<div class="content">
				<el-row>
					<el-col :span="12">
						<div @click="changeRouter('daily')">
							<div class="items">
								<el-image width="42px" height="42px" :src="getIcon('add-share')" />
							</div>
							<div class="text">创建分享</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div @click="changeRouter('hoilday')">
							<div class="items">
								<el-image width="42px" height="42px" :src="getIcon('add-holiday')" />
							</div>
							<div class="text">休假报备免分享</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="margin-box"></div>
		<div class="box">
			<div class="title"> 汇报给我的分享（共{{ callDailyList.pagination.total }}条） </div>
			<div class="content">
				<HtTable :tableHeader="callDailyList.header" :tableData="callDailyList.data"
					@handelRowClick="params => handelRowClick(params, 'dailyDetail')" />
				<el-pagination :page-size="10" :pager-count="5" layout="prev, pager, next"
					:total="callDailyList.pagination.total"
					@current-change="params => handleCurrentChange(params, 'callDailyList')"
					:current-page.sync="callDailyList.pagination.currentPage">
				</el-pagination>
			</div>
		</div>
		<div class="margin-box"></div>
		<div class="box">
			<div class="title"> 我的历史分享（共{{ myDailyList.pagination.total }}条） </div>
			<div class="content">
				<HtTable :tableHeader="myDailyList.header" :tableData="myDailyList.data"
					:pagination="myDailyList.pagination"
					@handelRowClick="params => handelRowClick(params, 'dailyDetail')" />
				<el-pagination :page-size="10" :pager-count="5" layout="prev, pager, next"
					:total="myDailyList.pagination.total"
					@current-change="params => handleCurrentChange(params, 'myDailyList')"
					:current-page.sync="myDailyList.pagination.currentPage">
				</el-pagination>
			</div>
		</div>
		<div class="margin-box"></div>
		<div class="box">
			<div class="title"> 历史报备情况（共{{ hoildayList.pagination.total }}条） </div>
			<div class="content">
				<HtTable :tableHeader="hoildayList.header" :tableData="hoildayList.data"
					@handelRowClick="params => handelRowClick(params, 'hoildayDetail')" />
				<el-pagination :page-size="10" :pager-count="5" layout="prev, pager, next"
					:total="hoildayList.pagination.total"
					@current-change="params => handleCurrentChange(params, 'hoildayList')"
					:current-page.sync="hoildayList.pagination.currentPage">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment"
import HtTable from "@/components/HtTable.vue"
import { mapState } from "vuex"

export default {
	components: { HtTable },
	computed: {
		...mapState({
			userInfo: state => state.userInfo
		})
	},
	data () {
		return {
			callDailyList: {
				header: [
					{ label: "汇报人", prop: "title", type: "text", width: "200px" },
					{ label: "分享日期", prop: "rpCurrentDate" },
				],
				data: [],
				pagination: {
					total: 0,
					currentPage: 1
				},
			},
			myDailyList: {
				header: [
					{ label: "分享人", prop: "title", type: "text", width: "200px" },
					{ label: "分享日期", prop: "rpCurrentDate" },
				],
				data: [],
				pagination: {
					total: 0,
					currentPage: 1
				},
			},
			hoildayList: {
				header: [
					{ label: "休假开始日期", prop: "startDate" },
					{ label: "休假结束日期", prop: "endDate" },
					{ label: "报备人", prop: "showName" },
				],
				data: [],
				pagination: {
					total: 0,
					currentPage: 1
				},
			},
		}
	},
	created () {
		this.initCallDailyList()
		this.initMyDailyList()
		this.initHoildayList()
	},
	methods: {
		handleCurrentChange (page, type) {
			this[type].pagination.currentPage = page
			switch (type) {
				case "callDailyList":
					this.initCallDailyList()
					break;
				case "myDailyList":
					this.initMyDailyList()
					break;
				case "hoildayList":
					this.initHoildayList()
					break;
			}
		},
		handelRowClick (row, name) {
			this.$router.push({
				name: name,
				query: { id: row.id }
			})
		},
		initCallDailyList () {
			this.$https.get(this.$api.shareList, {
				// startDate: moment().format("YYYY-MM-DD"),
				// endDate: moment().format("YYYY-MM-DD"),
				current: this.callDailyList.pagination.currentPage - 1,
				pageSize: 10
			}).then(res => {
				const data = res.data || []
				this.callDailyList.data = data.map(el => {
					const userInfo = this.userInfo.platformExtraData.accsMap[el.accId]
					return Object.assign(el, { title: "鱼你在一起 " + userInfo.showName, rpCurrentDate: moment(el.rpCurrentDate).format("YYYY-MM-DD") })
				})
				this.callDailyList.pagination = {
					total: res.count,
					currentPage: res.currentPage + 1
				}
			})
		},
		initMyDailyList () {
			this.$https.get(this.$api.myDailyList, {
				// startDate: moment().format("YYYY-MM-DD"),
				// endDate: moment().format("YYYY-MM-DD"),
				current: this.myDailyList.pagination.currentPage - 1,
				pageSize: 10
			}).then(res => {
				const data = res.data || []
				this.myDailyList.data = data.map(el => {
					const userInfo = this.userInfo.platformExtraData.accsMap[el.accId]
					return Object.assign(el, { title: "鱼你在一起 " + userInfo.showName, rpCurrentDate: moment(el.rpCurrentDate).format("YYYY-MM-DD") })
				})
				this.myDailyList.pagination = {
					total: res.count,
					currentPage: res.currentPage + 1
				}
			})
		},
		initHoildayList () {
			this.$https.get(this.$api.myRpLeavePageList, {
				// startDate: moment().format("YYYY-MM-DD"),
				// endDate: moment().format("YYYY-MM-DD"),
				current: this.hoildayList.pagination.currentPage - 1,
				pageSize: 10
			}).then(res => {
				const data = res.data || []
				this.hoildayList.data = data.map(el => {
					const userInfo = this.userInfo.platformExtraData.accsMap[el.accId]
					return Object.assign(el, { showName: userInfo.showName, startDate: moment(el.startDate).format("YYYY-MM-DD"), endDate: moment(el.endDate).format("YYYY-MM-DD") })
				})
				this.hoildayList.pagination = {
					total: res.count,
					currentPage: res.currentPage + 1
				}
			})
		},
		getIcon (path) {
			return require('@/assets/icon/' + path + '.png')
		},
		changeRouter (path) {
			this.$router.push({
				name: path
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.containers {
	position: relative;
	.box {
		position: relative;
		border-radius: 5px;

		.title {
			color: #999;
			line-height: 40px;
			padding: 0 10px;
			border-radius: 10px;
			font-size: 14px;
		}

		.content {
			padding: 0 10px;
			position: relative;

			.items {
				display: flex;
				justify-content: center;
				margin-top: 10px;
				font-size: 14px;
				cursor: pointer;
			}

			.text {
				margin: 10px 0 20px 0;
				text-align: center;
				color: #333;
				font-size: 13px;
			}


			.el-pagination {
				text-align: right;
			}

			.empty-data {
				color: #999;
				text-align: center;
				line-height: 200px;
			}
		}
	}

	.margin-box {
		height: 8px;
		background-color: #f5f5f5;
	}
}
</style>