<template>
    <div class="HtUserSelect">
        <el-drawer title=" " :visible="visible" direction="btt" size="50%" @close="closeDrawer">
            <el-input v-model="keyword" placeholder="请输入用户名" style="padding: 20px 20px 0 20px;"></el-input>
            <div class="select-user-box">
                <div class="user-item" v-for="(item, index) in userList" :key="index" @click="handelSelectUser(item)" v-show="item.showName.includes(keyword)">
                    <el-avatar class="logo" size="small"
                        src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                    <span class="name">{{ item.showName }}</span>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapState } from "vuex"

export default {
    data () {
        return {
            keyword: ""
        }
    },
    props: {
        visible: Boolean,
        position: Object,
        queryString: String
    },
    computed: {
        ...mapState({
            userList: state => {
                return Object.values(state.userInfo.platformExtraData.accsMap)
            }
        })
    },
    methods: {
        closeDrawer () {
            this.$emit('onHide')
        },
        handelSelectUser (user) {
            this.keyword = ""
            this.$emit('onPickUser', user)
        }
    }
}
</script>

<style lang="scss" scoped>
.HtUserSelect {
    position: relative;

    .select-user-box {
        padding: 10px 20px;

        .user-item {
            line-height: 30px;
            border-bottom: 1px solid #eee;
            overflow: hidden;
            padding: 8px 0;
            cursor: pointer;

            .logo {
                float: left;
            }

            .name {
                margin-left: 10px;
                font-size: 13px;
                color: #333;
            }
        }
    }
}
</style>

<style>
.el-drawer {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.el-drawer__header {
    display: none !important;
}
</style>