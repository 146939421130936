import axios from 'axios' // 引入axios
import { Message } from 'element-ui';//按需引入element中所需的组件
import Router from '@/router'
import qs from 'qs';

const instance = axios.create({
  baseURL: "https://ynh5.bjddlm.com/apph5",
  // baseURL: "",
  timeout: 60000
})

// 请求拦截器
instance.interceptors.request.use((config) => {
  const userInfo = sessionStorage.getItem("admninUserInfo") ? JSON.parse(sessionStorage.getItem("admninUserInfo")) : {}
  if (userInfo.token) {
    config.headers.satoken = userInfo.token || ""
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {

      return Promise.reject(response);
    }
  }, error => {
    if (error.response.status === 401 && error.response.data.code == 'no_auth') {
      sessionStorage.removeItem("admninUserInfo")
      Router.push({
        name: "login"
      })
    } else {
      Message.error(error.response.data)
    }
    return Promise.reject(error.response);
  })

// get请求
export function get(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: data
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// post请求
export function post(url, data = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data, headers).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

// post请求
export function postForm(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, qs.stringify(data), { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }, responseType: "arraybuffer" }).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

export default { get, post, postForm }