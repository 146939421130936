
function getQueryString (name) {
    let url = decodeURI(window.location.href)
    let p = url.split('?')[1] || ""//截取问号后面参数
    let keyValue = p.split('&');//以&为分割转换成数组
    let obj = {};//用来接收参数的
    for (let i = 0; i < keyValue.length; i++) {
        let item = keyValue[i].split('=');//继续分割
        obj[item[0]] = item[1];//type为键，1为值给obj对象
    }
    return obj[name]
}

export default { getQueryString }
