import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/dashboard/index.vue'

import DailyAdd from '@/views/daily/index.vue'
import DailyDetail from '@/views/daily/detail.vue'

import hoilday from '@/views/hoilday/index.vue'
import hoildayDetail from '@/views/hoilday/detail.vue'


const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/login"
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/daily',
      name: 'daily',
      component: DailyAdd,
    },
    {
      path: '/dailyDetail',
      name: 'dailyDetail',
      component: DailyDetail,
    },
    {
      path: '/hoilday',
      name: 'hoilday',
      component: hoilday,
    },
    {
      path: '/hoildayDetail',
      name: 'hoildayDetail',
      component: hoildayDetail,
    },
  ]
})
router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem("admninUserInfo")) {
    if (!Store.state.userInfo.token) Store.dispatch("setUserInfo", JSON.parse(sessionStorage.getItem("admninUserInfo")))
    next()  // 程序正常继续运行
  } else {
    next()   // 程序正常继续运行
  }
})

Vue.use(Router)

export default router
