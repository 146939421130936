<template>
    <div class="container">
        <div class="content">
            <div class="form">
                <div class="form-item">
                    <div class="form-label">分享日期 <span class="red">*</span></div>
                    <div class="form-content">{{ date }} </div>
                </div>
                <div class="form-item">
                    <div class="form-label">分享人 <span class="red">*</span></div>
                    <div class="form-content">{{ userInfo.loginInfo.name }}</div>
                </div>
                <div class="form-item">
                    <div class="form-label">分享类型 <span class="red">*</span></div>
                    <div class="form-content">{{ rpType && userInfo.types.RpType[rpType] }}</div>
                </div>
                <div class="form-item">
                    <div class="form-label">汇报对象 </div>
                    <div class="form-content">{{ reportAcc }}</div>
                </div>
                <div class="form-item">
                    <div class="form-label">推送群聊 </div>
                    <div class="form-content">{{ reportGroup }}</div>
                </div>
            </div>
            <div class="tips">
                <div class="tips-title">温馨提示 </div>
                <div>汇报对象&推送群聊如需调整，请联系人力行政中心-刘雨涛</div>
            </div>
            <div class="form-item">
                <div class="form-label">工作分享 <span class="red">*</span> <el-tooltip class="item" effect="dark"
                        placement="left">
                        <div slot="content">
                            <p>{{ rpType && userInfo.types.RpType[rpType] }}分享内容格式要求</p>
                            <p v-for="(opt, index) in userInfo.platformExtraData.qwAppRpTypeKeywords.rpTypeMap[rpType]"
                                :key="index">{{ opt }}</p>
                        </div>
                        <i class="el-icon-question"></i>
                    </el-tooltip></div>
                <HtEditor height="150px" ref="HtEditor" @handelCallUser="handelCallUser" />
            </div>
        </div>
        <div class="btns">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确认</el-button>
        </div>
    </div>
</template>

<script>
import HtEditor from "@/components/HtEditor.vue"
import moment from "moment"
import { mapState } from "vuex"

export default {
    components: { HtEditor },
    data() {
        return {
            date: "",
            content: '',
            rpType: "",
            // reportors: "",
            reportAcc: "",
            reportGroup: "",
            tipsList: [],
            editorOption: {
                modules: {
                    toolbar: false
                },
                theme: 'snow',
                placeholder: '请输入日报内容'
            },
            callUser: []
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            isAndroid: state => state.isAndroid,
            isWx: state => state.isWx,
        })
    },

    methods: {
        initRpSetting() {
            this.$https.get(this.$api.loginAccRpSettings).then(res => {
                const rpAccs = res.rpAccs.replace(new RegExp("#", "g"), "")
                const rpGroups = res.rpGroups.replace(new RegExp("#", "g"), "")
                let reportAcc = []
                let reportGroup = []
                if (rpAccs) {
                    rpAccs.split(",").forEach(el => {
                        reportAcc.push(this.userInfo.platformExtraData.accsMap[el].showName)
                    })
                }
                if (rpGroups) {
                    rpGroups.split(",").forEach(el => {
                        reportGroup.push(this.userInfo.platformExtraData.qwAppRpCgRobot.cgRobots[el].title)
                    })
                }
                this.reportAcc = reportAcc.join("，")
                this.reportGroup = reportGroup.join("，")
            })
        },
        initCheck() {
            this.$https.get(this.$api.checkRp).then(res => {
                if (res.ok) {
                    this.date = moment(res.data.date).format("YYYY-MM-DD")
                    this.rpType = res.data.rpType
                } else {
                    this.$alert(res.data, '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 50);
                        }
                    });
                }
            })
        },
        handelCallUser(user) {
            this.callUser.push(user)
        },
        cancel() {
            this.$router.go(-1)
        },
        confirm() {
            let content = this.$refs.HtEditor.$refs.editor.innerHTML
            this.userInfo.platformExtraData.qwAppRpTypeKeywords.rpTypeMap[this.rpType].forEach(el => {
                if (content.includes(el)) {
                    content = content.replace(el, ("<span style='font-size: 16px;font-weight:bold;'>" + el + "</span>"))
                }
            })
            const atAccList = this.callUser.filter(el => content.includes('@' + el.name))
            this.$https.post(this.$api.addDaily, {
                "content": content,
                "type": "day",
                "atAccList": atAccList.map(el => el.id).join(",") || ""
            }).then(res => {
                const data = res
                this.$message.success("分享成功")
                setTimeout(() => {
                    this.$router.push({
                        name: "dailyDetail",
                        query: {
                            id: data.id
                        }
                    })
                }, 50)
            })
        }
    },
    mounted() {
        if (this.isAndroid && this.isWx) {
            const _this = this
            wx.ready(function () {
                _this.tipsList = Object.values(_this.userInfo.platformExtraData.qwAppRpTypeKeywords.rpTypeMap)
                _this.initCheck()
                _this.initRpSetting()
                wx.onHistoryBack((function () {
                    window.location.href = window.location.origin + "/#/dashboard"
                    return false
                }))
            })
        } else {
            this.tipsList = Object.values(this.userInfo.platformExtraData.qwAppRpTypeKeywords.rpTypeMap)
            this.initCheck()
            this.initRpSetting()
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    width: 100%;
    height: 100%;

    .content {
        position: absolute;
        top: 0;
        bottom: 50px;
        width: 100%;
        overflow: auto;
        background: #fff;

        .form {
            position: relative;
            padding-top: 10px;
        }

        .form-item {
            padding: 0 10px;
            margin-bottom: 5px;

            .form-label {
                color: #666;
                font-size: 13px;
                line-height: 30px;

                .red {
                    color: red;
                    margin-left: 5px;
                }
            }

            .form-content {
                color: #333;
                border: 1px solid #dfdede;
                border-radius: 4px;
                padding: 10px;
                min-height: 16px;
                font-size: 14px;
                position: relative;
                line-height: 1.2;

                .el-icon-arrow-down {
                    position: absolute;
                    right: 10px;
                    top: 12px;
                }
            }

            .textarea {
                border: 1px solid #dfdede;
                width: 100%;
                border-radius: 4px;
                background: #fff;
                padding: 5px 0;
                font-size: 12px;
            }

            input::-webkit-input-placeholder {
                color: #999;
            }

            input::-moz-input-placeholder {
                color: #999;
            }

            input::-ms-input-placeholder {
                color: #999;
            }
        }

        .tips {
            padding: 10px;
            color: #888;
            font-size: 12px;
            margin-bottom: 8px;

            .tips-title {
                font-size: 16px;
                color: #444;
                margin-bottom: 8px;
            }
        }

        .editor {
            height: 300px;
        }

        .ql-snow {
            border-radius: 4px !important;
        }
    }

    .btns {
        position: absolute;
        text-align: center;
        bottom: 10px;
        width: 100%;

        .el-button {
            width: 42%;
            display: inline-block;
            line-height: 20px;
        }

        .el-button--primary {
            background: #409EFF;
            color: #fff;
        }
    }
}
</style>

<style>
.el-message-box {
    width: 60%;
    max-width: 420px;
}
</style>


