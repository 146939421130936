<template>
    <div class="container">
        <div class="content">
            <div class="form">
                <div class="form-item">
                    <div class="form-label">报备人 <span class="red">*</span></div>
                    <div class="form-content">{{ userInfo.loginInfo.name }} </div>
                </div>
                <div class="form-item">
                    <div class="form-label">本次报备对象 <span class="red">*</span></div>
                    <div class="form-content">{{ reportors }}</div>
                </div>
                <div class="form-item">
                    <div class="form-label">休假开始时间 <span class="red">*</span></div>
                    <el-date-picker v-model="startDate" type="date" placeholder="选择日期" :editable="false"
                        value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div class="form-item">
                    <div class="form-label">休假结束时间 <span class="red">*</span></div>
                    <el-date-picker v-model="endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                        :editable="false"></el-date-picker>
                </div>
                <div class="form-item">
                    <div class="form-label">报备原因 <span class="red">*</span></div>
                    <el-input type="textarea" placeholder="请输入报备原因" v-model="reason"
                        :autosize="{ minRows: 4, maxRows: 4 }"></el-input>
                </div>
            </div>
        </div>
        <div class="btns">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确认</el-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex"
export default {
    data() {
        return {
            options: [],
            startDate: "",
            endDate: "",
            reason: '',
            reportors: "",
            lockDate: "",
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            isAndroid: state => state.isAndroid,
            isWx: state => state.isWx,
        })
    },
    methods: {
        initRpSetting() {
            this.$https.get(this.$api.loginAccRpSettings).then(res => {
                const rpAccs = res.rpAccs.replace(new RegExp("#", "g"), "")
                let reportors = []
                if (rpAccs) {
                    rpAccs.split(",").forEach(el => {
                        reportors.push(this.userInfo.platformExtraData.accsMap[el].showName)
                    })
                }
                this.reportors = reportors.join("，")
            })
        },
        cancel() {
            this.$router.go(-1)
        },
        confirm() {
            if (!this.startDate) {
                this.$message.warning("请选择休假开始时间")
                return
            }
            if (!this.endDate) {
                this.$message.warning("请选择休假结束时间")
                return
            }
            if (!this.reason) {
                this.$message.warning("请输入报备原因")
                return
            }
            if (this.endDate < this.startDate) {
                this.$message.warning("休假结束时间不得早于休假开始时间")
                return
            }
            this.$confirm("是否确认报备<strong style='color: red;font-size: 20px;'> " + (moment(this.endDate).diff(this.startDate, "day") + 1) + " </strong>天", '提示', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$https.post(this.$api.addHoilday, {
                    "startDate": moment(this.startDate).format("YYYY-MM-DD"),
                    "endDate": moment(this.endDate).format("YYYY-MM-DD"),
                    "content": this.reason,
                }).then(res => {
                    const data = res
                    this.$message.success("报备成功")
                    setTimeout(() => {
                        this.$router.push({
                            name: "hoildayDetail",
                            query: {
                                id: data.id
                            }
                        })
                    }, 50)
                })

            }).catch(() => {

            });
        },
    },
    mounted() {
        if (this.isAndroid && this.isWx) {
            const _this = this
            wx.ready(function () {
                _this.initRpSetting()
                wx.onHistoryBack((function () {
                    window.location.href = window.location.origin + "/#/dashboard"
                    return false
                }))
            })
        } else {
            this.initRpSetting()
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    width: 100%;
    height: 100%;

    .content {
        position: absolute;
        top: 0;
        bottom: 50px;
        width: 100%;
        overflow: auto;
        background: #fff;

        .form {
            position: relative;
            padding-top: 10px;
        }

        .form-item {
            padding: 0 10px;
            margin-bottom: 5px;

            .form-label {
                color: #666;
                font-size: 13px;
                line-height: 30px;

                .red {
                    color: red;
                    margin-left: 5px;
                }
            }

            .form-content {
                color: #333;
                border: 1px solid #dfdede;
                border-radius: 4px;
                padding: 10px;
                min-height: 16px;
                font-size: 14px;
                position: relative;
                line-height: 1.2;
            }
        }
    }

    .btns {
        position: absolute;
        text-align: center;
        bottom: 10px;
        width: 100%;

        .el-button {
            width: 42%;
            display: inline-block;
            line-height: 20px;
        }

        .el-button--primary {
            background: #409EFF;
            color: #fff;
        }
    }
}
</style>
