<template>
    <div class="box">
        <!--文本框-->
        <div class="editor" :style="{ 'min-height': height }" :placeholder="placeholder" ref="editor" contenteditable="true"
            @keyup="handkeKeyUp"></div>
        <!--选项-->
        <HtUserSelect v-if="showDialog" :visible="showDialog" :queryString="queryString" @onPickUser="handlePickUser"
            @onHide="handleHide" @onShow="handleShow" />
    </div>
</template>
<script>
import HtUserSelect from './HtUserSelect.vue'
export default {
    name: 'sandBox',
    components: { HtUserSelect },
    props: {
        "placeholder": {
            typoe: String,
            default: "请输入"
        },
        "height": {
            type: String,
            default: "32px"
        },
        "isFocus": {
            type: Boolean,
            default: false
        }
    },
    watch: {
        "isFocus": function (val) {
            if (val) {
                this.$refs.editor.focus()
            }
        },
        "placeholder": function () {
            this.$refs.editor.innerHTML = ""
        }
    },
    data() {
        return {
            content: "",
            node: '', // 获取到节点
            user: '', // 选中项的内容
            endIndex: '', // 光标最后停留位置
            queryString: '', // 搜索值
            showDialog: false, // 是否显示弹窗
        }
    },
    methods: {
        clearValue() {
            this.$refs.editor.innerHTML = ""
        },
        // 获取光标位置
        getCursorIndex() {
            const selection = window.getSelection()
            return selection.focusOffset // 选择开始处 focusNode 的偏移量
        },
        // 获取节点
        getRangeNode() {
            const selection = window.getSelection()
            return selection.focusNode // 选择的结束节点
        },
        // 是否展示 @
        showAt() {
            let status
            const node = this.getRangeNode()
            if (!node || node.nodeType !== Node.TEXT_NODE) return false
            const content = node.textContent || ''
            const regx = /@([^@\s]*)$/
            const match = regx.exec(content.slice(0, this.getCursorIndex()))
            status = match && match.length === 2
            if (status && content.split("@")[content.split("@").length - 1] != '') {
                status = false
            }
            return status
        },
        // 获取 @ 用户
        getAtUser() {
            const content = this.getRangeNode().textContent || ''
            const regx = /@([^@\s]*)$/
            const match = regx.exec(content.slice(0, this.getCursorIndex()))
            if (match && match.length === 2) {
                return match[1]
            }
            return undefined
        },
        // 创建标签
        createAtButton(user) {
            const btn = document.createElement('span')
            btn.style.display = 'inline-block'
            btn.style.color = '#409EFF'
            btn.dataset.user = JSON.stringify(user)
            btn.className = 'at-button'
            btn.contentEditable = 'false'
            btn.textContent = `@${user.name}`
            const wrapper = document.createElement('span')
            wrapper.style.display = 'inline-block'
            wrapper.contentEditable = 'false'
            const spaceElem = document.createElement('span')
            spaceElem.style.whiteSpace = 'pre'
            spaceElem.textContent = '\u200b'
            spaceElem.contentEditable = 'false'
            const clonedSpaceElem = spaceElem.cloneNode(true)
            wrapper.appendChild(spaceElem)
            wrapper.appendChild(btn)
            wrapper.appendChild(clonedSpaceElem)
            return wrapper
        },
        replaceString(raw, replacer) {
            return raw.replace(/@([^@\s]*)$/, replacer)
        },
        // 插入@标签
        replaceAtUser(user) {
            const node = this.node
            if (node && user) {
                const content = node.textContent || ''
                const endIndex = this.endIndex
                const preSlice = this.replaceString(content.slice(0, endIndex), '')
                const restSlice = content.slice(endIndex)
                const parentNode = node.parentNode
                const nextNode = node.nextSibling
                const previousTextNode = new Text(preSlice)
                const nextTextNode = new Text('\u200b' + restSlice) // 添加 0 宽字符
                const atButton = this.createAtButton(user)
                parentNode.removeChild(node)
                // 插在文本框中
                if (nextNode) {
                    parentNode.insertBefore(previousTextNode, nextNode)
                    parentNode.insertBefore(atButton, nextNode)
                    parentNode.insertBefore(nextTextNode, nextNode)
                } else {
                    parentNode.appendChild(previousTextNode)
                    parentNode.appendChild(atButton)
                    parentNode.appendChild(nextTextNode)
                }
                // 重置光标的位置
                const range = new Range()
                const selection = window.getSelection()
                range.setStart(nextTextNode, 0)
                range.setEnd(nextTextNode, 0)
                selection.removeAllRanges()
                selection.addRange(range)
            }
        },
        // 键盘抬起事件
        handkeKeyUp() {
            if (this.showAt()) {
                const node = this.getRangeNode()
                const endIndex = this.getCursorIndex()
                this.node = node
                this.endIndex = endIndex
                this.queryString = this.getAtUser() || ''
                this.showDialog = true
            } else {
                this.showDialog = false
            }
        },
        // 插入标签后隐藏选择框
        handlePickUser(user) {
            this.replaceAtUser(user)
            this.user = user
            this.showDialog = false
            this.$emit("handelCallUser", user)
        },
        // 隐藏选择框
        handleHide() {
            this.showDialog = false
        },
        // 显示选择框
        handleShow() {
            this.showDialog = true
        }
    },
    mounted() {
        document.addEventListener('paste', e => {
            // 阻止默认的复制事件
            e.preventDefault()
            let txt = e.clipboardData.getData('text/plain')
            document.execCommand("insertText", false, txt);
        })
    },
}
</script>
 
<style lang="scss" scoped>
.editor {
    margin: 0 auto;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: left;
    padding: 5px 10px;
    overflow: auto;
    line-height: 30px;

    font-size: 16px !important;

    &:focus {
        outline: none;
    }
}

.editor:empty::before {
    content: attr(placeholder);
    color: #ddd;
}
</style>
