import Vue from 'vue'
import App from '@/App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.css'
import "normalize.css/normalize.css"
import 'font-awesome/css/font-awesome.min.css'
import router from '@/router'
import store from '@/store'
import https from '@/request'
import api from '@/api'
// import Vconsole from 'vconsole'

Vue.config.productionTip = false
Vue.prototype.$https = https
Vue.prototype.$api = api
Vue.use(ElementUI)


// let vConsole = new Vconsole()

// Vue.use(vConsole);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
